
export const bestSellers = [
  {
    value: "acuvue-moist-30-normal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist",
    title: "1-Day Acuvue Moist",
    image: "1-Day-Acuvue-Moist-30pk",
    price: "44.99",
    sale_price: "33.75",
    cc_product_id: "1765066866788",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-moist",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
    title: "1-Day Acuvue Moist",
    image: "1-Day-Acuvue-Moist-90pk",
    price: "82.99",
    sale_price: "62.25",
    cc_product_id: "2620861448292",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-2",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-2",
    title: "Acuvue 2",
    image: "Acuvue-2-6pk",
    price: "42.99",
    sale_price: "32.25",
    cc_product_id: "1765074927716",
    days: "88",
    quantity: 6
  },
  {
    value: "acuvue-define-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-define-30pk",
    title: "1-Day Acuvue Define",
    image: "1-Day-Acuvue-Define-30pk-blue",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "3409278664804",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-oyasis-hydraluxe",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
    title: "Acuvue Oasys 1-Day with Hydraluxe",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-90pk",
    price: "99.99",
    sale_price: "75.00",
    cc_product_id: "1765069357156",
    days: "90",
    quantity: 90
  },
  {
    value: "brand-acuvue-oyasis-max-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-max-1-day-30pk",
    title: "Acuvue Oasys Max 1-Day",
    image: "Acuvue-Oasys-Max-1-Day-30pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "7173256249444",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-oyasis-max-90",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-max-1-day-30pk",
    title: "Acuvue Oasys Max 1-Day",
    image: "Acuvue-Oasys-Max-1-Day-90pk",
    price: "127.99",
    sale_price: "96.00",
    cc_product_id: "7430125551716",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-hydraclear-plus",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus",
    image: "Acuvue-Oasys-Hydraclear-Plus-6pk",
    price: "44.99",
    sale_price: "33.75",
    cc_product_id: "1765078499428",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-hydraclear-plus-12",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus",
    image: "Acuvue-Oasys-Hydraclear-Plus-12pk",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "3409786896484",
    days: "180",
    quantity: 12
  },
  {
    value: "brand-acuvue-hydraclear-plus-24",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-24pk",
    title: "Acuvue Oasys with Hydraclear Plus",
    image: "Acuvue-Oasys-Hydraclear-Plus-24pk",
    price: "149.99",
    sale_price: "112.50",
    cc_product_id: "2620883206244",
    days: "360",
    quantity: 24
  },
  {
    value: "brand-acuvue-vita-12pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita-12pk",
    title: "Acuvue Vita",
    image: "Acuvue-Vita-12pk",
    price: "109.99",
    sale_price: "82.50",
    cc_product_id: "3409889198180",
    days: "360",
    quantity: 12
  },
  {
    value: "brand-acuvue-vita-6pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita",
    title: "Acuvue Vita",
    image: "Acuvue-Vita-6pk",
    price: "74.99",
    sale_price: "56.25",
    cc_product_id: "1765082136676",
    days: "180",
    quantity: 6
  },
  {
    value: "biotrue-oneday-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/biotrue-oneday-30pk",
    title: "Biotrue ONEday",
    image: "Biotrue-ONEDay-30pk",
    price: "29.99",
    sale_price: "22.50",
    cc_product_id: "3408967008356",
    days: "30",
    quantity: 30
  },
  {
    value: "biotrue-oneday-90",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday",
    image: "Biotrue-ONEDay-90pk",
    price: "67.99",
    sale_price: "51.00",
    cc_product_id: "1765111332964",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-biotrue-presbyopia",
    percent_off: "25",
    link: "https://www.contactscart.com/products/biofinity-toric",
    title: "Biotrue ONEday for Presbyopia",
    image: "Biotrue-ONEDay-Presbyopia-90pk",
    price: "96.99",
    sale_price: "72.75",
    cc_product_id: "3408938401892",
    days: "88",
    quantity: 90
  },
  {
    value: "infuse-one-day-90pk",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb Infuse One-Day",
    image: "B+L-Infuse-90pk",
    price: "99.99",
    sale_price: "75.00",
    cc_product_id: "6549011464292",
    days: "88",
    quantity: 90
  },
  {
    value: "bausch-lomb-ultra",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA",
    image: "B+L-Ultra-6pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "1765135908964",
    days: "180",
    quantity: 6
  },
  {
    value: "bausch-lomb-ultra-presb",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA for Presbyopia",
    image: "B+L-Ultra-Presbyopia-6pk",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "3341054640228",
    days: "180",
    quantity: 6
  },
  {
    value: "brand-acuvue-oasys-transitions-6pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-with-transitions-6pk",
    title: "Acuvue Oasys with Transitions",
    image: "Acuvue-Oasys-Transitions-6pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "4801375567972",
    days: "88",
    quantity: 6
  }
]


export const torics = [
  {
    value: "brand-acuvue-moist-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
    title: "1-Day Acuvue Moist for Astigmatism",
    image: "1-Day-Acuvue-Moist-Astigmatism-30pk",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "1765072797796",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-moist-astigmatism-90",
    percent_off: "25",
    link: "",
    title: "1-Day Acuvue Moist for Astigmatism",
    image: "1-Day-Acuvue-Moist-Astigmatism-90pk",
    price: "99.99",
    sale_price: "75.00",
    cc_product_id: "3344340287588",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-for-astigmatism-30pk",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-30pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "3409322016868",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-90",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-with-hydraluxe-for-astigmatism-90pk",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-90pk",
    price: "124.99",
    sale_price: "93.75",
    cc_product_id: "7165851992164",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
    title: "Acuvue Oasys for Astigmatism",
    image: "Acuvue-Oasys-Astigmatism-6pk",
    price: "59.99",
    sale_price: "45.00",
    cc_product_id: "1765080399972",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-vita-6pk-astig",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita-for-astigmatism",
    title: "Acuvue Vita for Astigmatism",
    image: "Acuvue-Vita-Astigmatism-6pk",
    price: "64.99",
    sale_price: "48.75",
    cc_product_id: "3379060179044",
    days: "180",
    quantity: 6
  },
  {
    value: "biotrue-oneday-astig-30",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday Astigmatism",
    image: "Biotrue-ONEDay-Astigmatism-30pk",
    price: "39.99",
    sale_price: "30.00",
    cc_product_id: "3408913530980",
    days: "30",
    quantity: 30
  },
  {
    value: "biotrue-oneday-astig-90",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday Astigmatism",
    image: "Biotrue-ONEDay-Astigmatism-90pk",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "3402116235364",
    days: "88",
    quantity: 90
  },
  {
    value: "bausch-lomb-astig",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA for Astigmatism",
    image: "B+L-Ultra-Astigmatism-6pk",
    price: "64.99",
    sale_price: "48.75",
    cc_product_id: "3341501268068",
    days: "180",
    quantity: 6
  }

]


export const multifocal = [
  {
    value: "acuvue-moist-30-multifocal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-multifocal-30pk",
    title: "1-Day Acuvue Moist Multifocal",
    image: "1-Day-Acuvue-Moist-Multifocal-30pk",
    price: "59.99",
    sale_price: "45.00",
    cc_product_id: "3338889855076",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-moist-90-multifocal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-multifocal-90pk",
    title: "1-Day Acuvue Moist Multifocal",
    image: "1-Day-Acuvue-Moist-Multifocal-90pk",
    price: "114.99",
    sale_price: "86.25",
    cc_product_id: "3341075808356",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-oasys-max-30",
    percent_off: "25",
    link: "",
    title: "Acuvue Oasys Max 1-Day Multifocal",
    image: "Acuvue-Oasys-Max-1-Day-Multifocal-30pk",
    price: "72.99",
    sale_price: "54.75",
    cc_product_id: "7430134497380",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-oasys-multifocal-6",
    percent_off: "25",
    link: "",
    title: "Acuvue Oasys Multifocal",
    image: "Acuvue-Oasys-Multifocal-6pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "6883191193700",
    days: "88",
    quantity: 6
  }


]


export const avgProductsIntake = [
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Lenses",
    image: "",
    price: "20.00",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "30",
    quantity: 30
  },
  {
    value: "hydro",
    percent_off: "0",
    link: "",
    title: "Hydro by Hubble Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: "24.99",
    sale_price: "9.99",
    cc_product_id: "hydro",
    days: "30",
    quantity: 30
  },
  {
    value: "skyhy",
    percent_off: "0",
    link: "",
    title: "SkyHy by Hubble Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: "29.99",
    sale_price: "17.99",
    cc_product_id: "skyhy",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
    title: "Acuvue Oasys for Astigmatism 6pk",
    image: "Acuvue-Oasys-for-Astigmatism-6pk.png",
    price: "59.99",
    sale_price: "45.00",
    cc_product_id: "1765080399972",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-moist",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
    title: "1-Day Acuvue Moist",
    image: "1-Day-Acuvue-Moist-90pk.png",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "2620861448292",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-oyasis-hydraluxe",
    percent_off: "20",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
    title: "Acuvue Oasys 1-Day with Hydraluxe",
    image: "Acuvue-Oasys-1-Day-With-Hydraluxe.png",
    price: "104.99",
    sale_price: "84.00",
    cc_product_id: "1765069357156",
    days: "90",
    quantity: 90
  },
  {
    value: "brand-dailies-aquacomfort-plus",
    percent_off: "25",
    link: "https://www.contactscart.com/products/dailies-aqua-comfort-plus-90pk",
    title: "DAILIES AquaComfort Plus",
    image: "DAILIES-AquaComfort-Plus.png",
    price: "74.99",
    sale_price: "56.25",
    cc_product_id: "1765114970212",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-biotrue-presbyopia",
    percent_off: "20",
    link: "https://www.contactscart.com/products/biofinity-toric",
    title: "Biotrue ONEday for Presbyopia 90pk",
    image: "Biotrue-ONEday-Presbyopia-90pk.png",
    price: "99.99",
    sale_price: "80.00",
    cc_product_id: "3408938401892",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-moist-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
    title: "1-Day Acuvue Moist for Astigmatism 30pk",
    image: "1-Day-Acuvue-Moist-Astigmatism-30pk.png",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "1765072797796",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-hydraclear-plus",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus 6pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-6pk",
    price: "49.99",
    sale_price: "32.99",
    cc_product_id: "1765078499428",
    days: "90",
    quantity: 6
  },
  {
    value: "dailies-total-one",
    percent_off: "20",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "DAILIES TOTAL1 90pk",
    image: "DAILIES-TOTAL1-90pk.png",
    price: "114.99",
    sale_price: "92.00",
    cc_product_id: "2620910534756",
    days: "88",
    quantity: 90
  },
  {
    value: "biotrue-oneday-90",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday 90pk",
    image: "Biotrue-ONEDay-90pk",
    price: "72.99",
    sale_price: "47.99",
    cc_product_id: "1765111332964",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-moist-30-normal",
    percent_off: "35",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist",
    title: "1-Day Acuvue Moist 30pk",
    image: "1-Day-Acuvue-Moist-30pk",
    price: "37.99",
    sale_price: "24.99",
    cc_product_id: "1765066866788",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-moist",
    percent_off: "35",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
    title: "1-Day Acuvue Moist 90pk",
    image: "1-Day-Acuvue-Moist-90pk",
    price: "83.99",
    sale_price: "54.99",
    cc_product_id: "2620861448292",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-moist-30-multifocal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-multifocal-30pk",
    title: "1-Day Acuvue Moist Multifocal 30pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "59.99",
    sale_price: "45.00",
    cc_product_id: "3338889855076",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-moist-90-multifocal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-multifocal-90pk",
    title: "1-Day Acuvue Moist Multifocal 90pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "117.99",
    sale_price: "88.50",
    cc_product_id: "3341075808356",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-2",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-2",
    title: "Acuvue 2 6pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "42.99",
    sale_price: "32.25",
    cc_product_id: "1765074927716",
    days: "88",
    quantity: 6
  },
  {
    value: "acuvue-define-30",
    percent_off: "60",
    link: "https://www.contactscart.com/products/1-day-acuvue-define-30pk",
    title: "1-Day Acuvue Define 30pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "59.99",
    sale_price: "24.00",
    cc_product_id: "3409278664804",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-for-astigmatism-30pk",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 30pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "3409322016868",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-90",
    percent_off: "20",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-with-hydraluxe-for-astigmatism-90pk",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 90pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "124.99",
    sale_price: "100.00",
    cc_product_id: "7165851992164",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-oyasis-hydraluxe",
    percent_off: "20",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
    title: "Acuvue Oasys 1-Day with Hydraluxe",
    image: "Acuvue-Oasys-1-Day-With-Hydraluxe.png",
    price: "104.99",
    sale_price: "84.00",
    cc_product_id: "1765069357156",
    days: "90",
    quantity: 90
  },
  {
    value: "brand-acuvue-oyasis-max-30",
    percent_off: "20",
    link: "https://www.contactscart.com/products/acuvue-oasys-max-1-day-30pk",
    title: "Acuvue Oasys Max 1-Day 30pk",
    image: "Acuvue-Oasys-1-Day-With-Hydraluxe.png",
    price: "56.99",
    sale_price: "42.75",
    cc_product_id: "7173256249444",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-hydraclear-plus",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus 6pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-6pk",
    price: "49.99",
    sale_price: "32.99",
    cc_product_id: "1765078499428",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-hydraclear-plus-12",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus 12pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-12pk",
    price: "89.99",
    sale_price: "58.99",
    cc_product_id: "3409786896484",
    days: "180",
    quantity: 12
  },
  {
    value: "brand-acuvue-hydraclear-plus-24",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-24pk",
    title: "Acuvue Oasys with Hydraclear Plus 24pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-24pk",
    price: "149.99",
    sale_price: "97.50",
    cc_product_id: "2620883206244",
    days: "360",
    quantity: 24
  },
  {
    value: "brand-acuvue-oasys-transitions-6pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-with-transitions-6pk",
    title: "Acuvue Oasys with Transitions 6pk",
    image: "30-off-AcuvueOasyswithHydraclearPlus6pk_front.jpg",
    price: "52.99",
    sale_price: "39.75",
    cc_product_id: "4801375567972",
    days: "88",
    quantity: 6
  },
  {
    value: "brand-acuvue-vita-12pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita-12pk",
    title: "Acuvue Vita 12pk",
    image: "30-off-AcuvueOasyswithHydraclearPlus6pk_front.jpg",
    price: "118.99",
    sale_price: "95.20",
    cc_product_id: "3409889198180",
    days: "360",
    quantity: 12
  },
  {
    value: "brand-acuvue-vita-6pk",
    percent_off: "35",
    link: "https://www.contactscart.com/products/acuvue-vita",
    title: "Acuvue Vita 6pk",
    image: "Acuvue-Vita-6pk",
    price: "74.99",
    sale_price: "48.99",
    cc_product_id: "1765082136676",
    days: "180",
    quantity: 6
  },
  {
    value: "brand-acuvue-vita-6pk-astig",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita-for-astigmatism",
    title: "Acuvue Vita for Astigmatism 6pk",
    image: "30-off-AcuvueOasyswithHydraclearPlus6pk_front.jpg",
    price: "72.99",
    sale_price: "58.40",
    cc_product_id: "3379060179044",
    days: "180",
    quantity: 6
  },
  {
    value: "biotrue-oneday-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/biotrue-oneday-30pk",
    title: "Biotrue ONEday 30pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "34.99",
    sale_price: "26.25",
    cc_product_id: "3408967008356",
    days: "30",
    quantity: 30
  },
  {
    value: "biotrue-oneday-90",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday 90pk",
    image: "Biotrue-ONEDay-90pk",
    price: "72.99",
    sale_price: "47.99",
    cc_product_id: "1765111332964",
    days: "88",
    quantity: 90
  },
  {
    value: "biotrue-oneday-astig-30",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday Astigmatism 30pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "39.99",
    sale_price: "30.00",
    cc_product_id: "3408913530980",
    days: "30",
    quantity: 30
  },
  {
    value: "biotrue-oneday-astig-90",
    percent_off: "20",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday Astigmatism 90pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "80.99",
    sale_price: "64.80",
    cc_product_id: "3402116235364",
    days: "88",
    quantity: 90
  },
  {
    value: "infuse-one-day-90pk",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb Infuse One-Day 90pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "99.99",
    sale_price: "75.00",
    cc_product_id: "6549011464292",
    days: "88",
    quantity: 90
  },
  {
    value: "bausch-lomb-ultra",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA 6pk",
    image: "B+L-Ultra-6pk",
    price: "54.99",
    sale_price: "35.99",
    cc_product_id: "1765135908964",
    days: "180",
    quantity: 6
  },
  {
    value: "bausch-lomb-astig",
    percent_off: "20",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA for Astigmatism 6pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "69.99",
    sale_price: "56.00",
    cc_product_id: "3341501268068",
    days: "180",
    quantity: 6
  },
  {
    value: "bausch-lomb-ultra-presb",
    percent_off: "20",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA for Presbyopia 6pk",
    image: "Biotrue-ONEday-90pk.png",
    price: "79.99",
    sale_price: "64.00",
    cc_product_id: "3341054640228",
    days: "180",
    quantity: 6
  },


]
